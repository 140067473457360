<template>
    <nut-collapse
    v-model:active="activeName"
    title-icon="horizontal"
    title-icon-color="#498ff2"
    title-icon-size="20px"
    title-icon-position="left"
    icon="down-arrow"
    :accordion="true"
    >
    <nut-collapse-item :title="item.name" :name="item.device" :sub-title="item.nowFrequency" v-for="item in lists" :key="item.id" v-model:class="statusColor">      
      <div class="row-border-css text-center">
        <div>当前空气质量</div>
          <nut-row  type="flex" justify="center" align="center">
            <nut-col :span="8" v-for="(val, index) in item.sensor" :key="index">
                <div class="flex-content">              
                  <nut-countup
                    class="sensor-size-padding"
                    :init-num="val"
                    speed="0"
                  >
                  </nut-countup>
                </div>
            </nut-col>
          </nut-row>   
      </div>
      <div class="row-border-css text-center" v-if="item.pm25Level !== null">
          <div>空气质量PM2.5值等级</div>
          <nut-row :gutter="10">
            <nut-col :span="8" v-for="(val, index) in item.pm25Level" :key="index">
                <div class="flex-content">
                  <div v-if="index == 0">低</div>
                  <div v-else-if="index == 1">中</div>
                  <div v-else-if="index == 2">高</div>
                  <nut-inputnumber v-model="item.pm25Level[index]" min="0" max="999" step="10"/>
                </div>
            </nut-col>
          </nut-row>  
      </div>
      <div class="row-border-css text-center" v-if="item.pm25Critical !== null">
          <div>启动风机的PM2.5值</div>
          <nut-row :gutter="24">
            <nut-col :span="24" >
                <div class="flex-content">
                  <nut-inputnumber v-model="item.pm25Critical" min="1" max="999" step="10"/>
                </div>
            </nut-col>
          </nut-row>  
      </div>
      <div class="row-border-css text-center" v-if="item.frequencyLevel !== null">
          <div>风机频率等级(Hz)</div>
          <nut-row :gutter="10">
            <nut-col :span="8" v-for="(val, index) in item.frequencyLevel" :key="index">
                <div class="flex-content">  
                  <div v-if="index == 0">低</div>
                  <div v-else-if="index == 1">中</div>
                  <div v-else-if="index == 2">高</div>       
                  <nut-inputnumber v-model="item.frequencyLevel[index]" min="1" max="50" />
                </div>
            </nut-col>
          </nut-row>   
      </div>
      <div class="row-border-css text-center" v-if="item.onTime !== null">
          <div>开启降噪时间</div> 
          <nut-row :gutter="10">
            <nut-col :span="8" v-for="(val, index) in item.onTime" :key="index">
                <div class="flex-content">   
                  <div v-if="index == 0">时</div>
                  <div v-else-if="index == 1">分</div>
                  <div v-else-if="index == 2">秒</div>  
                  <nut-inputnumber v-model="item.onTime[index]" v-if="index == 0" min="0" max="23" />
                  <nut-inputnumber v-else v-model="item.onTime[index]" min="0" max="59" />
                </div>
            </nut-col>
          </nut-row>   
      </div>
      <div class="row-border-css text-center" v-if="item.offTime !== null">
          <div>关闭降噪时间</div> 
          <nut-row :gutter="10">
            <nut-col :span="8" v-for="(val, index) in item.offTime" :key="index">
                <div class="flex-content">   
                  <div v-if="index == 0">时</div>
                  <div v-else-if="index == 1">分</div>
                  <div v-else-if="index == 2">秒</div>  
                  <nut-inputnumber v-model="item.offTime[index]" v-if="index == 0" min="0" max="23" />
                  <nut-inputnumber v-else v-model="item.offTime[index]" min="0" max="59" />
                </div>
            </nut-col>
          </nut-row>   
      </div>    
      <div class="row-border-css text-center">
          <nut-row :gutter="10" v-if="item.frequency !== null">
              <nut-col :span="8">
                  <div class="flex-content">
                    <div>手动启停风机</div> 
                      <nut-switch v-model="item.status" @change="switchChange(item.device,item.status)" active-text="开" inactive-text="关" active-color="green" inactive-color="red" style="margin: auto;"/>
                  </div>
              </nut-col>
              <nut-col :span="8">
                  <div class="flex-content flex-content-light">
                    <div>延迟关闭风机(分)</div> 
                    <nut-inputnumber v-model="item.stopDate" min="1" max="30"/>                  
                  </div>
              </nut-col>
              <nut-col :span="8">
                  <div class="flex-content">
                    <div>降噪限定频率(Hz)</div> 
                    <nut-inputnumber v-model="item.frequency" min="1" max="50"/>                  
                  </div>
              </nut-col>
          </nut-row>   
          <nut-row :gutter="24" v-else>
              <nut-col :span="12">
                  <div class="flex-content">
                    <div>手动启停风机</div> 
                      <nut-switch v-model="item.status" @change="switchChange(item.device,item.status)" active-text="开" inactive-text="关" active-color="green" inactive-color="red" style="margin: auto;"/>
                  </div>
              </nut-col>
              <nut-col :span="12">
                  <div class="flex-content flex-content-light">
                    <div>延迟关闭风机(分)</div> 
                    <nut-inputnumber v-model="item.stopDate" min="1" max="30"/>                  
                  </div>
              </nut-col>
          </nut-row>  
      </div> 
      <div class="row-border-css text-center">
          <nut-row>
              <nut-col :span="12">
                  <div class="flex-content flex-content-light">
                    <div>WiFi在线时间</div> 
                    <div>{{item.wifiOnlineDate}}</div>                  
                  </div>
              </nut-col>
              <nut-col :span="12">
                  <div class="flex-content flex-content-light">
                    <div>PLC当前时间</div> 
                    <div>{{item.plcOnlineDate}}</div>                  
                  </div>
              </nut-col>
          </nut-row>
      </div>     
      <div class="row-border-css text-center">
          <nut-row>
              <nut-col :span="12">
                  <div class="flex-content">
                    <div>同步PLC时间</div> 
                    <nut-button type="info" size="small" @click="updateTime(item.device)">同步</nut-button>       
                  </div>
              </nut-col>
              <nut-col :span="12">
                 <div class="flex-content flex-content-light">
                    <div>跟随新风启动进水</div> 
                      <nut-switch v-model="item.fanWash" @change="changeFanWash(item.device,item.fanWash)" active-text="开" inactive-text="关" active-color="green" inactive-color="red" style="margin: auto;"/>
                  </div>
              </nut-col>
          </nut-row>
      </div>  
      <div class="row-border-css text-center" v-if="item.error !== null">
          <nut-row>
              <nut-col :span="12">
                  <div class="flex-content">
                    <div>手动消除故障</div>
                    <nut-button type="warning" size="small" @click="reset(item.device)">reset</nut-button>
                  </div>
              </nut-col>
              <nut-col :span="12">
                  <div class="flex-content flex-content-light">
                    <div>故障信息</div> 
                    <div>{{item.error}}</div>                 
                  </div>
              </nut-col>
          </nut-row>   
      </div>
      <nut-button block type="default" :loading="isLoading" @click="updateData(item)" color="linear-gradient(to right, #fc6f4e, #ee0a24)" >提交</nut-button>
      <nut-shortpassword
      title="请输入验证密码"
      desc=""
      tips=""
      v-model="password.value"
      v-model:visible="password.visible"
      :no-button="password.noButton"
      :length="password.length"
      :error-msg="password.errorMsg"
      @ok="methods.onOk">
      </nut-shortpassword>
    </nut-collapse-item>
    </nut-collapse>
</template>

<script>
import { reactive, ref, toRefs, getCurrentInstance } from "vue";
import md5 from "js-md5";
import Http from "../../utils/Http";
import { createSocket, sendWSmessage, sendWSPush, getToken } from "../../utils/WebSocket";
import config from "../../config/index";

export default {
  name: 'IotAirLis',
  props: {
    msg: String
  },
  methods: {
    getToken() {
      const url = new URL(window.location.href);
      Http.token = getToken(url.searchParams.get('t'));      
    },
    frequencyConvert(data, type) {
      if(typeof(data) == 'object'){   
        let temp = [];
        if(type == 1){
          temp = data.map((item) => {
            return item * 100;
          });
        }else{                 
          temp = data.map((item) => {
            return item / 100;
          });
        }    
        return  temp;
      }
      if(typeof(data) == 'number' || typeof(data) == 'string'){         
        if(type == 1){
          return data * 100;
        }else{
          return data / 100;
        }
      }
    },
    timeConvert(data, type){
      if(type == 1){
        return data * 600;
      }else{
        return data / 600;
      }
    }, 
    async updateData (data) { 
      this.password.visible = true    
      this.password.objData = {
        device:data.device,
        frequencyLevel:(data.frequencyLevel !== null && data.frequencyLevel.length > 0) ? this.frequencyConvert(data.frequencyLevel, 1) : ["","",""],
        pm25Level:data.pm25Level ?? ["","",""],
        stopDate:this.timeConvert(data.stopDate, 1),
        frequency:data.frequency !== null ? this.frequencyConvert(data.frequency, 1) : "",
        offTime:data.offTime ?? ["","",""],
        onTime:data.onTime ?? ["","",""],
        pm25Critical:data.pm25Critical ?? "",
      }
    },
    async setData () {
      const obj = await Http.post(
        this.baseUrlHttp + 'iot/air/device/update/data',this.password.objData          
      )
      if(obj.code == 200){
        alert(obj.msg)
      }else{
        alert(obj.msg)
      }
    },
    async reset (device) {
      this.password.visible = true    
      this.password.objData = {
        device:device,
        reset:2,
      }
    },
    async updateTime (device) {
      this.password.visible = true    
      this.password.objData = {
        device:device,
        updatePlcTime:1,
      }
    },
    async switchChange(device,status){
      const obj = await Http.post(
        this.baseUrlHttp + 'iot/air/device/update/data',
        {
          device:device,
          status:status ? 1 : 2
        }
      )
      if(obj.code == 200){
        alert(obj.msg)
      }else{
        alert(obj.msg)
      }
    },
    async changeFanWash(device,fanWash){
      const obj = await Http.post(
        this.baseUrlHttp + 'iot/air/device/update/data',
        {
          device:device,
          fanWash:fanWash ? 1 : 2
        }
      )
      if(obj.code == 200){
        alert(obj.msg)
      }else{
        alert(obj.msg)
      }
    },
    async getDeviceList(){
        let list = await Http.post(
          this.baseUrlHttp + 'iot/air/device/list',
          {
            device:this.device
          }
        )
        if(list.code == 200){
          this.lists = list.data.map((item) => {
            item.status         = item.status == 1 ? '运行中 ' : '停止 '
            item.nowFrequency   = item.status + this.frequencyConvert(item.nowFrequency, 0) + 'Hz'
            item.sensor         = item.sensor.length > 0 ? item.sensor : ["0","0","0"]
            item.pm25Level      = item.pm25Level.length > 0 ? item.pm25Level : null
            item.frequencyLevel = item.frequencyLevel.length > 0 ? this.frequencyConvert(item.frequencyLevel, 0) : null
            item.stopDate       = this.timeConvert(item.stopDate, 0)
            item.frequency      = item.frequency == 0 ? null : this.frequencyConvert(item.frequency, 0)
            item.offTime        = item.offTime.length > 0 ? item.offTime : null
            item.onTime         = item.onTime.length > 0 ? item.onTime : null
            item.pm25Critical   = item.pm25Critical == 0 || item.pm25Critical == '' ? null : item.pm25Critical
            item.error          = item.pm25Critical !== null || item.error == '' ? null : item.error
            return item;
          })
        }else{
          alert(list.msg)
        }
    },
    linkSocket(){        
        // if(createSocket(this.baseUrlWs + "auto") == 'ok')
        if(createSocket(this.baseUrlWs) == 'ok')
        {
          setInterval(() => {
            sendWSPush(this.message);
          }, 1000);
        }
    },
    getList() {
        setInterval(() => {
          if(sendWSmessage.length > 6){
            this.sendWSmessage()
          }
        }, 1000);
    },
    sendWSmessage(){
      JSON.parse(sendWSmessage).forEach((element,index) => {
          this.lists[index].status         = (element.status == 1 ? true : false)
          this.lists[index].nowFrequency   = (element.status == 1 ? '运行中 ' : '停止 ') + this.frequencyConvert(element.nowFrequency, 0) + 'Hz'
          this.lists[index].sensor         = element.sensor.length > 0 ? element.sensor : ["0","0","0"]
          this.lists[index].wifiOnlineDate = element.wifiOnlineDate
          this.lists[index].plcOnlineDate  = element.plcOnlineDate
          this.lists[index].fanWash        = (element.fanWash == 1 ? true : false)
          this.md5Data.forEach((item) => {
            if(item.id == element.id){
              item.pm25Level.toString() != element.pm25Level.toString() && (this.lists[index].pm25Level = element.pm25Level.length > 0 ? element.pm25Level : null)
              item.frequencyLevel.toString() != element.frequencyLevel.toString() && (this.lists[index].frequencyLevel = element.frequencyLevel.length > 0 ? this.frequencyConvert(element.frequencyLevel, 0) : null)
              item.stopDate != element.stopDate && (this.lists[index].stopDate = this.timeConvert(element.stopDate, 0))
              item.frequency != element.frequency && (this.lists[index].frequency = element.frequency == 0 ? null : this.frequencyConvert(element.frequency, 0))
              item.offTime.toString() != element.offTime.toString() && (this.lists[index].offTime = element.offTime.length > 0 ? element.offTime : null)
              item.onTime.toString() != element.onTime.toString() && (this.lists[index].onTime  = element.onTime.length > 0 ? element.onTime : null)
              item.pm25Critical != element.pm25Critical && (this.lists[index].pm25Critical = element.pm25Critical == 0 || element.pm25Critical == '' ? null : element.pm25Critical)
              this.lists[index].error = this.lists[index].pm25Critical !== null || element.error == '' ? null : element.error
            }   
          })   
      });
      this.md5Data = JSON.parse(sendWSmessage).map((element) => {
        return element;
      })
    }
  },
  setup() {
    let { proxy }          = getCurrentInstance();
    const statusColor      = ref('start-color');
    const baseUrlHttp      = config.baseUrl.devHttp;
    const baseUrlWs        = config.baseUrl.devWs;
    const device           = ref('');
    const activeName       = ref(0);
    const bgImage          = ref('number.png');
    const message          = reactive({"name":"elwynn"})
    const md5Lists         = reactive({
                              md5Data:[{}]
                              })
    const data             = reactive({
                              lists:[{
                                  "id": 1,
                                  "device": "elwynn",
                                  "name": "测试007",
                                  "sensor": ["0", "0", "0"],
                                  "nowFrequency": "停止 0Hz",
                                  "status": 2,
                                  "frequencyLevel":["0", "0", "0"],
                                  "pm25Level":["0", "0", "0"],
                                  "offTime":["0", "0", "0"],
                                  "onTime":["0", "0", "0"],
                                  "stopDate":0,
                                  "frequency":1,
                                  "error":"",
                                  "wifiOnlineDate":"",
                                  "plcOnlineDate":"",
                                  "fanWash":1,
                                  "pm25Critical":0
                              },]})
    const methods          = {
                              async onOk() {     
                                const obj = await Http.post(
                                  proxy.baseUrlHttp + 'iot/air/device/net/password',
                                  {
                                    device:proxy.password.objData.device,
                                    password:md5(proxy.password.value),
                                  }          
                                )
                                if(obj.code == 200){
                                  proxy.setData()
                                  password.visible = false;
                                }else{
                                  proxy.password.value = ''
                                  proxy.password.errorMsg = obj.msg
                                }
                              },
                            };
    const password         = reactive({
                              visible: false,
                              noButton: false,
                              value: '',
                              errorMsg: '',
                              length: 6,
                              objData:{}
                            });
    return {
      password,
      methods,
      statusColor,
      baseUrlHttp,
      baseUrlWs,
      ...toRefs(data),
      ...toRefs(md5Lists),
      device,
      bgImage,
      activeName,
      message,
    };
  },
  mounted() {
    this.getToken();
    this.getDeviceList();
    this.linkSocket();
    this.getList();
  }
}
</script>

<style>
.nut-inputnumber {
  justify-content: center;
}
.row-border-css {
  border: 3px solid #f6f7f8;
  padding:10px 0px 10px 0px;
}
.text-center {
  text-align: center;
}
.sensor-size-padding {
  font-size: xx-large;
  padding: 0px !important;
}
.start-color {
  color: red !important;
}
.stop-color {
  color: greenyellow !important;
}
</style>