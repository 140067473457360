import { createApp } from 'vue'
import { Collapse, CollapseItem, Icon, Switch, Button, Layout, Row, Col, InputNumber, CountUp, ShortPassword, Popup } from '@nutui/nutui';
import App from './App.vue'
import router from './router'
import "@nutui/nutui/dist/style.css";
router.beforeEach((to, from, next)=>{
    if(to.meta.title){
        document.title = to.meta.title
    }
    next()
})
createApp(App).use(router).use(Collapse).use(CollapseItem).use(Icon).use(Switch).use(Button).use(Layout).use(Row).use(Col).use(InputNumber).use(CountUp).use(ShortPassword).use(Popup).mount('#app')