
import axios from 'axios';

const HTTP_METHOD_POST = 'POST';
 
class Http {
  token = '123';
  static post(url, data) {

    let config = () => {
      return {
        url: url,
        method: HTTP_METHOD_POST,
        data: data,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': this.token,
        },
      };
    };
    return new Promise((resolve, reject) => {
      axios(config()).then(res => {
        if(res.status === 200)
        {
          resolve(res.data)
        }
      })
      .catch((e) => {
        reject(e.response)
      });
    });
  }
}
 
export default Http;
 