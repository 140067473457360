export default {
    /**
     * @description 配置显示在浏览器标签的title
     */
    title: '空气智能控制系统',
    /**
     * @description api请求基础路径
     */
    baseUrl: {
      // devHttp: 'http://116.62.126.229:39501/',
      // devWs:   'ws://116.62.126.229:39502/',
      devHttp: 'https://air.elwynn.cn/',
      devWs:   'wss://airapi.elwynn.cn/',
      pro: ''
    },
  }
  